import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import Layout from '../components/layout'

import ContentColFour from '../components/acf-flexible_content/content-col_four'
import ContentColThree from '../components/acf-flexible_content/content-col_three'
import ContentColTwo from '../components/acf-flexible_content/content-col_two'
import ContentFullWidth from '../components/acf-flexible_content/content-col_fullwidth'
import ContentCentred from '../components/acf-flexible_content/content-col_centred'
import ContentVideo from '../components/acf-flexible_content/content-video'
import ContentStandoutQuote from '../components/acf-flexible_content/content-standout_quote'

import LayoutFullWidth from '../components/styled_components/style-full_width'

export default class ThankYouPage extends Component {
  render() {
    const { data } = this.props
    return (
      <Layout>
        <Helmet
          title={`Thank you for contacting us - IMG Sales Programs Ltd`}
          meta={[
            {
              name: 'keywords',
              content:
                'img sales programs, loyalty program, incentive program, digital strategy',
            },
            { name: 'robots', content: 'noindex' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <LayoutFullWidth>
          <h1 className="page__intro text-center">
            Thank you for contacting us
          </h1>
          <div
            className="page__intro"
            dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }}
          />
        </LayoutFullWidth>
        {data.wordpressPage.acf &&
          data.wordpressPage.acf.content_page &&
          data.wordpressPage.acf.content_page.map((field, i) => {
            if (field.__typename === 'WordPressAcf_text_block') {
              if (field.columns === '1/4') {
                return <ContentColFour key={field.id} node={field} />
              } else if (field.columns === '1/3') {
                return <ContentColThree key={field.id} node={field} />
              } else if (field.columns === 'full') {
                return <ContentFullWidth key={field.id} node={field} />
              } else if (field.columns === '2/3c') {
                return <ContentCentred key={field.id} node={field} />
              } else if (field.columns === '2/3l' || '2/3r' || '1/2') {
                return <ContentColTwo key={field.id} node={field} />
              }
            }
            if (field.__typename === 'WordPressAcf_video_block') {
              return <ContentVideo key={field.id} node={field} />
            }
            if (field.__typename === 'WordPressAcf_standout_quote') {
              return <ContentStandoutQuote key={field.id} node={field} />
            }
            return null
          })}
      </Layout>
    )
  }
}

ThankYouPage.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export const pageQuery = graphql`
  query staticThankYouPage($page_id: Int = 2919) {
    wordpressPage(wordpress_id: { eq: $page_id }) {
      id
      title
      content
      yoast {
        title
        metadesc
        metakeywords
        opengraph_title
        opengraph_description
      }
      acf {
        content_page {
          __typename
          ... on WordPressAcf_text_block {
            id
            background_colour
            columns
            text
            text_2
            text_3
            text_4
          }
        }
      }
    }
  }
`
